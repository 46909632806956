import { Box, styled } from "@mui/material";
import { FC } from "react";

const Spacer = styled("div")(({ theme }) => ({
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: "0",
    height: "10px",
  },
}));

const StyledFooter = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: 10,
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  left: "0",
  paddingLeft: "10px",
  paddingRight: "10px",
  display: "block",
  overflow: "auto",
}));

const Footer: FC = ({ children }) => (
  <Box>
    <Spacer />
    <StyledFooter>{children}</StyledFooter>
  </Box>
);

export default Footer;
