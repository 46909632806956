import { FC } from "react";
import { styled, Typography, Box, Card, Paper } from "@mui/material";
import SlideAppBar from "components/AppBar/SlideAppBar";
import Footer from "components/Footer";
import { Outlet } from "react-router-dom";
import Advertisement from "components/advertisement/AcornAd";
import "react-toastify/dist/ReactToastify.css";

const Main = styled("main")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const PortalLayout: FC = () => (
  <Main sx={{ backgroundColor: "#ebeefe" }}>
    <SlideAppBar />
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "80px",
        textAlign: "center",
        marginLeft: 2,
        marginRight: 2,
      }}
    >
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: `1fr ${theme.breakpoints.values.lg}px 1fr`,
          pb: "1rem",
          [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            pb: "unset",
          },
        })}
      >
        <Box />
        <Paper
          elevation={0}
          sx={{
            borderRadius: "unset",
          }}
        >
          <Box>
            <Outlet />
          </Box>
        </Paper>

        <Box
          sx={(theme) => ({
            width: "300px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          })}
        >
          <Advertisement />
        </Box>
      </Box>
    </Box>
    <Footer>
      <Typography>
        Company Information <b>Acorn Insurance and Financial Services Ltd</b> is
        authorised and regulated by the Financial Conduct Authority (FCA)
        Registration number 311873. <br /> Registered address: 98 Liverpool
        Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368
      </Typography>
    </Footer>
  </Main>
);

export default PortalLayout;
