import { Paper, styled, Typography, Box, Divider, Button } from "@mui/material";
import { FC, useEffect } from "react";
import TrustPilotWidget from "components/widget/TrustPilotWidget";
import Defato from "assets/images/Rating-Year-4-Colour-RGB.png";

const DefaqtoLogo = styled("img")(() => ({
  height: "110px",
  width: "180px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "10px",
  display: "block",
}));

const Advertisement: FC = () => {
  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        marginLeft: "0.57143em",
        mt: 2,
        paddingTop: "20px",
        borderRadius: 0,

        [theme.breakpoints.down("md")]: {
          width: "100%",
          marginLeft: "unset",
          my: 2,
        },
      })}
    >
      <Box sx={{ height: 150 }}>
        <TrustPilotWidget themeColor="light" height="100%" width="100%" />
      </Box>
      <Divider
        sx={{
          my: 1,
          borderBottomWidth: "0.2em",
        }}
      />
    </Paper>
  );
};

export default Advertisement;
