import { styled, Box } from "@mui/material";
import { useEffect } from "react";

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement | null) => void;
    };
  }
}

export interface ITrustPilotWidgetProps {
  height: string;
  width: string;
  themeColor: "dark" | "light";
}

const TrustPilotWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  height: "100%",
  animation: "$fadeIn 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both",
}));

const TrustPilotWidget = (props: ITrustPilotWidgetProps) => {
  useEffect(() => {
    const node = document.createElement("script");
    node.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    node.type = "text/javascript";
    node.async = true;
    const trustboxWrapper = document.getElementById("trustbox-wrapper");
    if (trustboxWrapper) {
      trustboxWrapper.appendChild(node);

      node.onload = () => {
        const trustbox = document.getElementById("trustbox");
        window.Trustpilot.loadFromElement(trustbox);
      };
    }
  }, []);

  const { height, width, themeColor } = props;

  return (
    <TrustPilotWrapper id="trustbox-wrapper">
      <Box
        sx={(theme) => ({
          position: "relative",
          top: "50%",
          left: "47%",
          transform: "translate(-50%, -50%)",
          "& iframe": {
            display: "block",
            margin: "auto",
          },
          [theme.breakpoints.down("md")]: {
            left: "50%",
          },
        })}
        id="trustbox"
        data-locale="en-GB"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="5b1a8b306cc55400016abf76"
        data-style-height={height}
        data-style-width={width}
        data-theme={themeColor}
        data-stars="4,5"
        data-review-languages="en"
      />
    </TrustPilotWrapper>
  );
};

export default TrustPilotWidget;
