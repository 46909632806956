import { createTheme } from "@mui/material";

export const FontArray = [
  "Lato",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

let theme = createTheme({
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          width: "1200px",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#ec6a3f",
      main: "#e94e1b",
      dark: "#a73610",
    },
    secondary: {
      light: "#7077ba",
      main: "#4e55a2",
      dark: "#353a6e",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: "#ebeefe",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1.025em",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: theme.palette.primary.main,
          fontWeight: 600,
        },
        h4: {
          color: theme.palette.primary.main,
        },
        h5: {
          color: theme.palette.primary.main,
        },
        h6: {
          color: theme.palette.primary.main,
        },
        h7: {
          color: theme.palette.primary.light,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.common.black,
        },
        notchedOutline: {
          borderColor: theme.palette.common.black,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body, html, #root,.App {
          height: 100%;
        }
        html {
          overflow-y: scroll;
        }
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: ${theme.palette.grey[500]} ${theme.palette.background.default};
        }
      
        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
          width: 12px;
        }
      
        *::-webkit-scrollbar-track {
          background: ${theme.palette.background.default};
        }
      
        *::-webkit-scrollbar-thumb {
          background-color: ${theme.palette.grey[500]};
          border-radius: 10px;
          border: 3px solid ${theme.palette.background.default};
        }`,
    },
  },
});

const themeExport = theme;
export default themeExport;
