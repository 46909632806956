import { Navigate, RouteObject } from "react-router-dom";
import PortalLayout from "layouts/PortalLayout";
import HomePage from "pages/HomePage";

export const ROUTE_PATHS = {
  home: "/home",
};

const Routes: RouteObject[] = [
  {
    path: "/",
    element: <PortalLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTE_PATHS.home} replace />,
      },
      {
        path: ROUTE_PATHS.home,
        element: <HomePage />,
      },
    ],
  },
];

export default Routes;
