import { Form, Formik, FormikHelpers } from "formik";
import isValidDate from "utils";
import {
  RENEWALS_ERROR_MESSAGE,
  datePickerFormat,
  apiDateFormat,
} from "utils/constants";
import * as Yup from "yup";
import {
  Typography,
  Checkbox,
  TextField,
  Container,
  TextFieldProps,
  Button,
  Box,
  styled,
  useTheme,
  useMediaQuery,
  Fade,
} from "@mui/material";
import { RenewalsClient } from "services/RenewalsClient";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FC, useState } from "react";
import { CheckCircleOutline, Publish } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";

interface IRenewalsFormValues {
  registration: string;
  dob: string;
  postcode: string;
  acceptTerms: boolean;
}

const initialValues = {
  registration: "",
  dob: "",
  postcode: "",
  acceptTerms: false,
};

const InputContainer = styled("div")(() => ({
  marginTop: "25px",
}));

const RenewalsSchema = Yup.object().shape({
  registration: Yup.string().required(RENEWALS_ERROR_MESSAGE.registration),
  dob: Yup.string().required(RENEWALS_ERROR_MESSAGE.dob).nullable(),
  postcode: Yup.string().required(RENEWALS_ERROR_MESSAGE.postcode),
});

const HomePage: FC = () => {
  const [isSubmitDisabled, disableSubmitButton] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const themeUtils = useTheme();
  const matches = useMediaQuery(themeUtils.breakpoints.down("md"));

  const onFormSubmit = async (
    values: IRenewalsFormValues,
    formikHelpers: FormikHelpers<IRenewalsFormValues>
  ) => {
    disableSubmitButton(true);
    setLoading(true);
    if (values.dob) {
      console.log(values.dob);
      const dob = new Date(values.dob);
      if (!isValidDate(dob)) {
        formikHelpers.setFieldError("dob", "Invalid Date");
      }
    }

    try {
      const client = new RenewalsClient(
        process.env.REACT_APP_BASE_URL_PCCVRENEWALS_API ?? ""
      );
      await client.processPolicyForRenewal({
        vehicleRegistrationNumber: values.registration,
        dateOfBirth: format(new Date(values.dob), apiDateFormat),
        postcode: values.postcode,
      });
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      toast("Error retrieving policy details. Please contact Acorn", {
        autoClose: 8000,
        position: matches
          ? toast.POSITION.BOTTOM_CENTER
          : toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        style: {
          background: themeUtils.palette.error.main,
          color: themeUtils.palette.common.white,
          fontSize: "1.2rem",
        },
        progressStyle: {
          background: themeUtils.palette.error.light,
        },
      });
      disableSubmitButton(false);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        paddingTop="5px"
        color="secondary"
      >
        Renew Now?
      </Typography>
      <Typography variant="subtitle1" align="left">
        You should have received your renewal offer by e-mail. This contains
        your renewal price along with your Statement of Fact, which reflects the
        information you have provided to us. <br /> <br /> Please check this
        document together with your policy information to ensure the cover still
        meets your needs. <br /> <br /> Please call our renewals team on{" "}
        <a href="tel:01704 339327">01704 339327</a> to discuss any incorrect
        information or questions, e.g. Change of occupation; change of named
        driver(s) etc. <br /> <br /> Failure to do so could invalidate your
        insurance and result in the renewal offer being withdrawn. Your policy
        will be renewed under the Direct Debit agreement as stated in your
        renewal offer. <br /> <br />
        Granite Finance will send your agreement separately along with your
        payment schedule. If the information we hold is accurate and up to date,
        please enter your details below to renew your policy for another year:
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        <a
          href="https://www.acorninsure.co.uk/docs/Terms-Of-Business.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Please click here for our full Terms of Business
        </a>
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={RenewalsSchema}
        onSubmit={onFormSubmit}
      >
        {(formik) => {
          return (
            <Box>
              {isSuccess ? (
                <Fade in timeout={400}>
                  <Box sx={{ margin: 4 }}>
                    <Typography variant="h3">Thank you.</Typography>
                    <Typography sx={{ margin: "20px" }}>
                      Your renewal details will be processed shortly.
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        color: theme.palette.success.main,
                        "& svg": {
                          height: "100px",
                          width: "100px",
                        },
                      })}
                    >
                      <CheckCircleOutline />
                    </Box>
                  </Box>
                </Fade>
              ) : (
                <Fade in timeout={400}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Box
                      sx={{
                        maxWidth: "500px",
                        margin: "auto",
                        padding: "15px",
                      }}
                    >
                      <InputContainer>
                        <TextField
                          error={
                            Boolean(formik.errors.registration) &&
                            formik.touched.registration
                          }
                          helperText={
                            formik.touched.registration &&
                            formik.errors.registration
                          }
                          id="registration"
                          label="Registration"
                          variant="outlined"
                          value={formik.values.registration}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextField
                          error={
                            Boolean(formik.errors.postcode) &&
                            formik.touched.postcode
                          }
                          helperText={
                            formik.touched.postcode && formik.errors.postcode
                          }
                          id="postcode"
                          label="Postcode"
                          variant="outlined"
                          value={formik.values.postcode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                        />
                      </InputContainer>
                      <InputContainer>
                        <DatePicker
                          label="Date of Birth"
                          value={formik.values.dob}
                          inputFormat={datePickerFormat}
                          openTo="year"
                          views={["year", "month", "day"]}
                          onChange={(newValue: any) => {
                            formik.setFieldValue("dob", newValue);
                          }}
                          renderInput={(
                            params: JSX.IntrinsicAttributes & TextFieldProps
                          ) => (
                            <TextField
                              {...params}
                              id="dob"
                              name="dob"
                              fullWidth
                              placeholder={datePickerFormat}
                              error={
                                formik.touched.dob && Boolean(formik.errors.dob)
                              }
                              helperText={
                                formik.touched.dob && formik.errors.dob
                              }
                            />
                          )}
                        />
                      </InputContainer>
                      <InputContainer>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.acceptTerms}
                              onChange={(value) =>
                                formik.setFieldValue(
                                  "acceptTerms",
                                  !formik.values.acceptTerms
                                )
                              }
                            />
                          }
                          label="I have read and acknowledged the terms"
                          id="checkLabel"
                        />
                      </InputContainer>

                      <LoadingButton
                        sx={{ marginTop: "15px" }}
                        startIcon={<Publish />}
                        type="submit"
                        color="secondary"
                        fullWidth
                        variant="contained"
                        size="large"
                        loading={isLoading}
                        disabled={
                          isSubmitDisabled || !formik.values.acceptTerms
                        }
                      >
                        Submit
                      </LoadingButton>
                    </Box>
                  </Form>
                </Fade>
              )}
            </Box>
          );
        }}
      </Formik>
    </Container>
  );
};

export default HomePage;
