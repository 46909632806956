import React, { FC } from "react";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material";
import ToolBar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import LogoImage from "assets/logo.svg";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const HideOnScroll = (props: Props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const Logo = styled("img")({
  maxWidth: "170px",
  paddingBottom: "10px",
});

const Link = styled("a")(({ theme }) => ({
  textAlign: "center",
  maxWidth: 220,
  marginLeft: "auto",
  marginRight: "auto",
  width: "50%",
  paddingTop: "5px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    margin: "auto",
  },
}));

const SlideAppBar: FC = () => (
  <HideOnScroll>
    <AppBar
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
      })}
    >
      <ToolBar sx={{ paddingLeft: 0 }}>
        <Link href="https://acorninsure.co.uk/" aria-label="Home">
          <Logo src={LogoImage} alt="logo" />
        </Link>
      </ToolBar>
    </AppBar>
  </HideOnScroll>
);

export default SlideAppBar;
